<script setup lang="ts">
import { Card, Icon, LoadingSpinner } from 'webcc-ui-components'
import AccessibleIconButton from '~/components/basic/AccessibleIconButton.vue'

const props = withDefaults(
  defineProps<{
    prefixIcon?: string
    suffixIcon?: string
    disabled?: boolean
    ariaLabelIcon?: string
    contentClass?: string | null
    loading?: boolean
  }>(),
  {
    prefixIcon: undefined,
    suffixIcon: undefined,
    ariaLabelIcon: undefined,
    disabled: false,
    contentClass: null,
    loading: false,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

const isCardDisabled = computed(() => props.loading || props.disabled)

function handleClick() {
  emit('click')
}

function handleKeyDown(evt: KeyboardEvent) {
  if (evt.code === 'Enter') {
    emit('click')
  }
}
</script>

<template>
  <Card
    class="[&&]:p-4"
    :class="{
      '[&&]:cursor-not-allowed': isCardDisabled,
    }"
    role="button"
    :tabindex="isCardDisabled ? null : 0"
    :aria-disabled="isCardDisabled"
    v-on="
      isCardDisabled ? {} : { click: handleClick, onKeyDown: handleKeyDown }
    "
  >
    <div
      class="w-full flex gap-x-4 items-center"
      :class="{ 'select-none': isCardDisabled }"
    >
      <slot name="prefix-icon">
        <Icon v-if="prefixIcon" size="lg" :graphic="prefixIcon" />
      </slot>

      <div
        class="w-full flex flex-col gap-y-1"
        :class="[contentClass, { 'text-txt-300': isCardDisabled }]"
      >
        <slot />
      </div>

      <slot name="suffix-icon">
        <LoadingSpinner v-if="loading" size="sm" class="[&&]:w-fit" />
        <AccessibleIconButton
          v-else-if="suffixIcon"
          :disabled="isCardDisabled"
          :label="ariaLabelIcon"
          :graphic="suffixIcon"
          :icon-class="{
            'text-txt-500': isCardDisabled,
          }"
        />
      </slot>
    </div>
  </Card>
</template>
